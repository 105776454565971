<template>
  <div class="container">
    <div class="fs-5 my-3 text-left">
      <button class="btn icon float-right" @click="copyToClipboard" title="Copy window URL to clipboard.">
        <font-awesome-icon icon="far fa-copy"></font-awesome-icon>
      </button>
      <button v-if="showAllAveragesButton" class="btn btn-outline-secondary float-right mx-3" title="Multi-Region"
        @click="goMultiRegion">
        View All {{ regionLevel }} {{ region && region.divisionName }} Averages
      </button>

      <button class="btn btn-outline-secondary mx-3" @click="$router.go(-1)">
        <font-awesome-icon icon="fa-caret-left"></font-awesome-icon>
      </button>

      <span v-if="regionId">{{ regionName.toUpperCase() }}</span>
      <span v-else-if="aClass">{{ aClass }} {{ divisionId === '1' ? 'GIRLS' : 'BOYS' }}</span>
      AVERAGES
    </div>
    <table class="w-100">
      <tr>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th v-for="event in useEvents" :key="'eventHeader' + event.id"
          class="text-center" style="line-height: 1.5em;">
          {{ event.eventDate.substring(5) }}<br>
          {{ event.hostTeamCode }}
        </th>
        <th>AVG</th>
        <th>
          <span v-if="aClass">Outings /</span>
          Strokes
        </th>
      </tr>
      <tr v-for="(player, idx) in sortedPlayers" :key="'player' + player.id"
        :class="[player.eventScores.length < closedEvents.length - (region ? region.maxDrops : 0) && 'text-danger',
          region && region.qualifiers && idx < region.qualifiers && 'fw-bold'
        ]">
        <td>{{ idx + 1 }}</td>
        <td>{{ player.lastName }}, {{ player.firstName }}</td>
        <td v-if="regionId" class="text-right">{{ player.teamCode }}</td>
        <td v-else class="text-left">{{ player.teamName }}</td>
        <td v-for="event in useEvents" :key="'eventScore' + idx + '-' + event.id"
          class="player-score text-center">
          <span v-if="!event.open"
            v-html="playerScore(player, event.id) || '-'"></span>
          <span v-else>&nbsp;</span>
        </td>
        <td>{{ playerAverage(player) }}</td>
        <td><span v-if="!regionId">{{ player.eventScores.length }} / </span>{{ totalStrokes(player) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'player-averages',
  // either regionId or (aClass and divisionId) will be provided, the latter of which
  // gives averages for all players matching an aClass (AA or AAA) region and the given division (boys or girls).
  props: ['players', 'events', 'regionId', 'aClass', 'divisionId'],
  created () {
    if (!this.regions.length) {
      this.getRegions()
    }
  },
  computed: {
    ...mapState(['regions']),
    showAllAveragesButton () {
      return this.region &&
        (this.region.divisionId === 0 || !/AA/.test(this.region.name))
    },
    useEvents () { return this.regionId ? this.events : [] },
    region () {
      return this.regions.find(r => r.id === +this.regionId)
    },
    regionName () {
      return (this.region && (this.region.name + ' ' + this.region.divisionName)) || 'unknown region'
    },
    regionLevel () {
      if (!this.region) { return this.aClass }
      if (/AAA/.test(this.region.name)) { return 'AAA' }
      if (/AA/.test(this.region.name)) { return 'AA' }
      return ''
    },
    sortedPlayers () {
      return this.players.slice(0).sort((a, b) => b.complete - a.complete || this.playerAverage(a) - this.playerAverage(b))
    },
    closedEvents () {
      return this.useEvents.filter(e => !e.open)
    },
    playerScore () {
      return (player, eventId) => {
        const scores = player.eventScores.filter(s => s && s.eventId === eventId)
        return scores
          ? (scores.map(s => `<span class="${this.countedScores(player).indexOf(s) === -1 ? 'dropped-score text-gray' : ''}">${s.score}</span>`).join(', '))
          : ''
      }
    },
    totalStrokes () {
      return player => this.countedScores(player).map(s => s.score).reduce((a, b) => a + b)
    },
    countEvents () {
      return this.useEvents.length - this.region.maxDrops
    },
    countedScores () {
      return player => {
        const scores = player.eventScores.filter(s => s)
        scores.sort((a, b) => a.score - b.score)
        return this.regionId ? scores.slice(0, this.countEvents) : scores
      }
    },
    playerAverage () {
      return player => (this.totalStrokes(player) / this.countedScores(player).length).toFixed(2)
    }
  },
  methods: {
    ...mapActions(['getRegions']),
    copyToClipboard () {
      navigator.clipboard.writeText(window.location.href)
      alert('URL copied to clipboard')
    },
    goMultiRegion () {
      this.$router.push(`/report/averages/${this.regionLevel}/${this.region.divisionId}`)
    }
  }
}
</script>

<style scoped>
.player-score { border: 1px solid gray; }

</style>
